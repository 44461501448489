<template>
    <div v-if="show">
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div class="mt-3 ml-0 mr-3 mb-2">
                                <b-button
                                    @click="handleSubmit"
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="cc-emails-operation">
                    <form @submit.prevent="handleSubmit" autocomplete="off">
                        <b-row>
                            <template v-for="(data,index) in formFields">
                                <div class="col-sm-12" v-if="index <= 0">
                                    <h3 class="text-uppercase">{{formFields[index + 1].process}}</h3>
                                    <hr />
                                </div>

                                <b-col cols="6">
                                    <div class="card mb-2">
                                        <div class="card-header p-2" v-b-toggle="`${data.type}`">
                                            {{$t('title.'+data.type.replaceAll(".", ""))}}
                                            <span class="when-open">
                                                <i class="fa fa-minus"></i>
                                            </span>
                                            <span class="when-closed">
                                                <i class="fa fa-plus"></i>
                                            </span>
                                        </div>
                                        <b-collapse :id="data.type">
                                            <div class="card-body p-3">
                                                <b-row>
                                                    <b-col sm="12">
                                                        <b-form-group
                                                                :label="$t('input.subject')+' *'"
                                                                label-for="subject"
                                                                :invalid-feedback="formErrors.first('subject')"
                                                                :description="data.tags"
                                                        >
                                                            <b-form-input
                                                                    id="subject"
                                                                    v-model="formFields[index].subject"
                                                                    type="text"
                                                                    :state="((formErrors.has('subject') ? false : null))"
                                                                    @focus="$event.target.select()"
                                                            ></b-form-input>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <b-row>
                                                    <b-col sm="12">
                                                        <b-form-group
                                                                :label="'CC '+ $t('input.emails')"
                                                                label-for="email"
                                                                :invalid-feedback="formErrors.first('cc_emails')"
                                                        >
                                                            <div :class="formErrors.first('cc_emails') ? 'is-invalid':''">
                                                                <a-select
                                                                        mode="tags"
                                                                        v-model="formFields[index].cc_emails"
                                                                        :default-value="formFields[index].cc_emails"
                                                                        @change="handleCcMailChange($event, index)"
                                                                >
                                                                </a-select>
                                                            </div>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <b-row>
                                                    <b-col sm="12">
                                                        <b-form-group
                                                                :label="'BCC '+ $t('input.emails')"
                                                                label-for="email"
                                                                :invalid-feedback="formErrors.first('bcc_emails')"
                                                        >
                                                            <div :class="formErrors.first('bcc_emails') ? 'is-invalid':''">
                                                                <a-select
                                                                        mode="tags"
                                                                        v-model="formFields[index].bcc_emails"
                                                                        :default-value="formFields[index].bcc_emails"
                                                                        @change="handleBccMailChange($event,index)"
                                                                >
                                                                </a-select>
                                                            </div>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </div>
                                </b-col><!--/b-col-->

                                <div class="col-sm-12 mt-4" v-if="index > 0 && formFields[index + 1] !== undefined && formFields[index + 1].process !== data.process">
                                    <h3 class="text-uppercase">{{formFields[index + 1].process}}</h3>
                                    <hr />
                                </div>
                            </template>
                        </b-row><!--/b-row-->
                    </form><!--/form-->
                </div><!--/.cc-emails-operation-->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
    </div>
</template>
<script>
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        subject: null,
        type: null,
        listener_type: null,
        bcc_emails: [],
        cc_emails: [],
    }

    export default {
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.emailSettings',
                listUrl: 'email/settings',
                formErrors: new Error({}),
                show: true,
                formFields: [],
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.emailSettings')
            this.getDetail()
        },
        methods: {
            handleCcMailChange(event, index) {
                this.formFields[index].cc_emails = _.filter(event, (item) => this.validateEmail(item))
            },
            handleBccMailChange(event, index) {
                this.formFields[index].bcc_emails = _.filter(event, (item) => this.validateEmail(item))
            },
            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: '/email/settings/create',
                        method: 'post',
                        data: {
                            emails: this.formFields.map(item => ({
                                ...item,
                                cc_emails: item.cc_emails ? ((_.isArray(item.cc_emails) ? item.cc_emails: [item.cc_emails])) : [],
                                bcc_emails: item.bcc_emails ? ((_.isArray(item.bcc_emails) ? item.bcc_emails: [item.bcc_emails])) : [],
                            }))
                        },
                    })

                    this.itemUpdated()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async getDetail() {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/email/settings/detail`,
                    })

                    const {data} = response
                    this.formFields = data.map((item) => {
                        return {
                            process: item.process,
                            subject: item.subject,
                            type: item.type,
                            listener_type: item.listener_type,
                            bcc_emails: ((item.bcc_emails) ? item.bcc_emails : []),
                            cc_emails: ((item.cc_emails) ? item.cc_emails : []),
                            tags: item.tags,
                        }
                    })
                } catch (e) {
                    this.itemEditFails()
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss" scoped>
    .when-closed,
    .when-open{
        position: absolute;
        top: 8px;
        right: 10px;
        color: gray;
    }

    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }

    .not-collapsed > .when-open,
    .collapsed > .when-closed{
        display: block;
    }
</style>
